const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.riotinto.forwoodsafety.com',
    APP_CLIENT_ID: 'qc9sv9cbrtep3c51sqv3b9a51',
    APIGATEWAY: 'https://de4ushaec8.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://fucaxqtx33.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'incidents-container-1.6.0',
    HOSTNAME: 'https://incidents.riotinto.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.riotinto.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.riotinto.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.riotinto.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

